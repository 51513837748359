/* External dependencies */
import { Div, Text } from 'atomize';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import './style.scss';
import Button from '@shared/ui/button';

export default function Banner() {
  return (
    <Div
      bg="text_color_for_teaser"
      d="flex"
      flexDir="column"
      justify="center"
      rounded="40px"
      p={{ y: { xs: '51px', md: '70px', lg: '107px' }, x: '10px' }}
      m={{ b: { xs: '50px', sm: '70px', md: '90px', lg: '100px' } }}
      pos="relative"
      overflow="hidden"
      textColor="white"
    >
      <Div
        m="0 auto"
        p={{ b: '32px', x: '10px' }}
        textSize={{
          xs: 'display1',
          sm: 'display2',
          md: 'subtitle',
          lg: 'title',
        }}
        textWeight="700"
        textAlign="center"
        data-aos="zoom-in"
        data-aso-delay="100"
        maxW={{ sm: '100%', md: '90%' }}
      >
        <Text tag="span" className="bg-linear" textAlign="center" d="unset">
          <Trans>increaseRecognitionText</Trans>{' '}
        </Text>
        <Trans>increaseRecognitionOfYourInstitution</Trans>
      </Div>
      <Div
        w="auto"
        p={{ y: '15px' }}
        m="0 auto"
        d="flex"
        justify="center"
        data-aos="zoom-in"
        data-aso-delay="200"
      >
        <Link style={{ display: 'inline-block' }} to="/pro-package">
          <Button
            className="primary-btn"
            width="100%"
            typeColor="primary"
            textColor="white"
          >
            <Text p={{ y: '16px' }} textSize={{ xs: 'body', sm: 'smallSize2' }}>
              <Trans>btnBanner</Trans>
            </Text>
          </Button>
        </Link>
      </Div>
      <Div
        w={{ xs: '222px', md: '413px' }}
        h={{ xs: '222px', md: '413px' }}
        pos="absolute"
        right="-100px"
        rounded="50%"
        bottom="-140px"
        className="linear"
      ></Div>
    </Div>
  );
}
