// External dependencies
import { Container, Div } from 'atomize';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

// Local dependencies
import ProAccountIcon from '../../../svg/ProAccountIcon';
import QrCodeText from '../../../svg/QrCodeText';
import QrCodeTextEn from '../../../svg/QrCodeTextEn';
import QrCodeTextKy from '../../../svg/QrCodeTextKy';
import Banner from '../banner/Banner';
import DownloadApp from '../downloadApp/DownloadApp';
import DownloadAppQRCode from '../downloadAppQRCode/DownloadAppQRCode';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import InfoBlock from '../infoBlock/InfoBlock';
import { InfoCardProps } from '../infoCard/InfoCard';
import InfoCards from '../infoCard/InfoCards';
import MainHero from '../mainHero/mainHero';
import { dataReviewsDoctors } from '../reviews/DataReview';
import Reviews from '../reviews/Reviews';
import './style.scss';

export default function ProPackage() {
  const data = useStaticQuery(query);
  const infoBlockData = [
    {
      backgroundColor: 'main_bg_color',
      title: <Trans>doctorsAccount</Trans>,
      subtitle: <Trans>openProfessionalAccountText</Trans>,
      image: getImage(data?.doctorAccount),
      flexDir: 'row',
    },
    {
      backgroundColor:
        'linear-gradient(90deg, rgba(140, 250, 199, 0.3) 0%, rgba(207, 214, 250, 0.3) 100%, rgba(207, 214, 250, 0.3) 100%)',
      title: <Trans>patients</Trans>,
      subtitle: <Trans>weHelpDoctorsText</Trans>,
      image: getImage(data?.doctorTreatingPatient),
      flexDir: 'row-reverse',
    },
  ];
  const infoCardsData: InfoCardProps[] = [
    {
      children: (
        <GatsbyImage image={getImage(data?.publishArticles)} alt="image" />
      ),
      height: '30%',
      imgHeight: '50%',
      title: <Trans>publishArticles</Trans>,
      text: <Trans>youcanPublishYourArticles</Trans>,
    },
    {
      children: <ProAccountIcon />,
      imgHeight: '50%',
      height: '30%',
      title: <Trans>openClinicProfile</Trans>,
      text: <Trans>tellAboutYorClinicText</Trans>,
    },
  ];
  const { language } = useI18next();

  return (
    <>
      <Header />
      <MainHero
        bg="main_bg_color"
        downloadButtons
        titlePro
        title={<Trans>doctorsAndClinics</Trans>}
        description={<Trans>registerInAppText</Trans>}
      >
        <Div
          m={{
            b: '0px',
            x: 'auto',
          }}
          maxW="612px"
          pos="relative"
        >
          <Div
            opacity={{ xs: '0', lg: '1' }}
            pos="absolute"
            top="40%"
            left="-50px"
            style={{ zIndex: '1' }}
          >
            <Div
              data-aos="zoom-in"
              data-aos-delay="1100"
              w={{ xs: '150px', sm: '200px' }}
            >
              <DownloadAppQRCode />
            </Div>
          </Div>
          <Div
            pos="absolute"
            bottom="0"
            left="-290px"
            opacity={{ xs: '0', xl: '1' }}
          >
            <Div data-aos="zoom-in" data-aos-delay="1200">
              {language === 'en' ? (
                <QrCodeTextEn />
              ) : language === 'ky' ? (
                <Div m={{ l: '-50px' }}>
                  <QrCodeTextKy />
                </Div>
              ) : (
                <QrCodeText />
              )}
            </Div>
          </Div>
          <GatsbyImage
            image={getImage(data?.doctor)}
            alt="doctor"
            objectFit="cover"
          />
        </Div>
      </MainHero>
      <Container>
        {infoBlockData &&
          infoBlockData.map((block, index) => (
            <InfoBlock
              key={index}
              backgroundColor={block.backgroundColor}
              title={block.title}
              subtitle={block.subtitle}
              paddingForImageInAbout={index === 1 ? '0px' : '25px'}
              image={block.image}
              flexDir={block.flexDir}
            />
          ))}
        <InfoCards data={infoCardsData} />
        <Div data-aos="fade-up">
          <Banner />
        </Div>
      </Container>
      <Reviews
        title={<Trans>whatDoctorsSayAboutUsText</Trans>}
        description={<Trans>doctorsReview</Trans>}
        dataReviews={dataReviewsDoctors}
      />
      <main>
        <Container>
          <DownloadApp />
        </Container>
      </main>
      <Footer />
    </>
  );
}

export const query = graphql`
  query {
    doctorAccount: file(relativePath: { eq: "doctor-account.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    doctorTreatingPatient: file(
      relativePath: { eq: "doctor-treating-patient.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    publishArticles: file(relativePath: { eq: "publish-articles.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    proImage: file(relativePath: { eq: "pro-image.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    doctor: file(relativePath: { eq: "doctor-with-not-smile.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
